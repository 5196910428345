import { FormattedMessage } from "services";
import { Box, Stack, Typography } from "@mui/material";
import Link from "next/link";

/**
 * Renders the MoveHQ Terms of Service Copy
 * @returns
 */
export const TermsOfServiceCopy: React.FC = () => {
  return (
    <Typography variant="sBody">
      <FormattedMessage id="disclaimer.body" />
      <Box sx={{ marginTop: 0.5 }}>
        <FormattedMessage id="disclaimer.body2" />
      </Box>
      <ul>
        <Stack gap={0.5}>
          <li>
            <FormattedMessage id="disclaimer.bullet1" />
          </li>
          <li>
            <FormattedMessage id="disclaimer.bullet2" />
          </li>
        </Stack>
      </ul>
    </Typography>
  );
};
