import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  ApolloProvider as AProvider,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { ApplicationRecord } from "models";
import { ReactNode } from "react";
import { getEnv } from "tools";
import { datadogRum } from "@datadog/browser-rum";

const httpLink = createHttpLink({
  uri: `${getEnv("NEXT_PUBLIC_DEPOT_HOST")}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = ApplicationRecord.jwt;

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      datadogRum.addError(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError)
    datadogRum.addError(`[GraphQL Network error]: ${networkError}`);
});

export const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: getEnv("NODE_ENV") === "development",
});

export const ApolloProvider = ({ children }: { children: ReactNode }) => (
  <AProvider client={apolloClient}>{children}</AProvider>
);
