import { Button } from "components/common";
import { FormattedMessage, useIntl } from "services";
import {
  useAuth,
  getLogoutInfo,
  setLogoutInfo,
} from "services/auth/AuthContext";
import styled from "@emotion/styled";
import { Alert, Box, Stack, Typography } from "@mui/material";
import { DollyImage } from "components/common/DollyImage";
import Link from "next/link";
import { H1 } from "components/common/Typography/typography";
import { PageWrapper } from "components/common/PageWrapper";
import { useEffect } from "react";
import { hscTosUrl } from "constant/externalUrls";

const Container = styled.div({
  position: "fixed",
  height: "100%",
  width: "100%",
  left: 0,
});

/**
 * Render the login display to the user using an okta widget
 * @returns ReactNode
 */
export function Login() {
  const auth = useAuth();
  const { formatMessage } = useIntl();
  const logoutInfo = getLogoutInfo();
  const login = async () => auth.oktaAuth?.signInWithRedirect();

  useEffect(() => {
    // Reset the logout info so that the logout messaging
    // doesn't stick indefinitely
    setLogoutInfo(null);
  }, []);

  return (
    <PageWrapper maxWidth="md">
      {logoutInfo === "user_idle" ? (
        <Box mb="24px">
          <Alert severity="warning">
            You have been logged out due to inactivity. Please log back in to
            continue.
          </Alert>
        </Box>
      ) : logoutInfo === "access_denied" ? (
        <Box mb="24px">
          <Alert severity="warning">
            Your were logged out because your account does not have access to
            this application. Please try logging in again.
          </Alert>
        </Box>
      ) : null}
      <Stack
        spacing={4}
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
      >
        <div>
          <Stack spacing={5} sx={{ maxWidth: 500 }}>
            <Stack spacing={1}>
              <H1>
                <FormattedMessage id="login.header" />
              </H1>
              <Typography variant="mBody">
                <FormattedMessage id="login.subheader" />
              </Typography>
            </Stack>
            <Stack spacing={3}>
              <Button
                onClick={login}
                ariaLabel={formatMessage({ id: "ariaLabels.loginButton" })}
                size="block"
              >
                <FormattedMessage id={"actions.login"} />
              </Button>
              <Typography variant="sBody">
                <FormattedMessage id="login.notice" />{" "}
                <strong>
                  <Link href={hscTosUrl}>
                    <FormattedMessage id="login.tosLink" />
                  </Link>
                </strong>
              </Typography>
            </Stack>
          </Stack>
        </div>
        <div>
          <DollyImage alt={formatMessage({ id: "imageAlt.accent.login" })} />
        </div>
      </Stack>
    </PageWrapper>
  );
}
