import counselingWelcomeSVG from "assets/counseling/welcome.svg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export function DollyImage({ alt }: { alt: string }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <img
      width={matches ? "300px" : undefined}
      src={counselingWelcomeSVG.src}
      alt={alt}
    />
  );
}
