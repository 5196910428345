import { useEffect } from "react";
import { middleware } from "models/middleware";
import { useSurveillanceMode } from "hooks/useSurveillanceMode";
import { useNotify } from "services/notification";
import { useIntl } from "services/translation";

export const SurveillanceModeError: React.FC = () => {
  const surveillanceMode = useSurveillanceMode();
  const notify = useNotify();
  const { formatMessage } = useIntl();

  useEffect(() => {
    const showAuthError = (response: Response) => {
      if (response.status === 403 && surveillanceMode) {
        notify(formatMessage({ id: "surveillanceMode.authError" }), {
          variant: "error",
        });
      }
    };

    if (surveillanceMode) middleware.afterFilters.push(showAuthError);

    return () => {
      const i = middleware.afterFilters.indexOf(showAuthError);
      if (i >= 0) middleware.afterFilters.splice(i, 1);
    };
  }, [surveillanceMode]);

  return null;
};
