import React, { useEffect } from "react";
import { useIdle } from "react-use";
import { useAuth } from "services/auth/AuthContext";
import { getEnv } from "tools";

const IDLE_EXPIRY = Number(getEnv("NEXT_PUBLIC_IDLE_TIMEOUT_MS")) || 3600000;

const IdleTimer: React.FC<{ onIdle: () => void }> = ({ onIdle }) => {
  const isIdle = useIdle(IDLE_EXPIRY);
  useEffect(() => {
    if (isIdle) {
      onIdle();
    }
  }, [isIdle]);
  return null;
};

/**
 * Responsible for signing the user out when idle
 * @returns
 */
export const IdleLogout: React.FC = () => {
  const { authState, signOut } = useAuth();
  if (authState?.isAuthenticated) {
    return <IdleTimer onIdle={() => signOut("user_idle")} />;
  }
  return null;
};
