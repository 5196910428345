"use client";
import { ThemeProvider } from "styles";
import { PropsWithChildren, Suspense } from "react";
import { Translations } from "services/translation";
import { Notification } from "services/notification";
import { Datadog } from "services/datadog";
import { Authentication } from "services/auth/Authentication";
import React from "react";
import Script from "next/script";
import { OrderLoaderProvider } from "services/orders/OrderLoaderContext";
import { ChatBox } from "components";
import { LicenseInfo } from "@mui/x-license";
import { ApolloProvider } from "services/apollo/ApolloProvider";
import { getEnv } from "tools";
import { IdleLogout } from "services/auth/IdleLogout";
import { SurveillanceModeError } from "services/surveillanceModeError";
import { LDProvider } from "services/launch-darkly";

// Sets up MUI Pro License
LicenseInfo.setLicenseKey(getEnv("NEXT_PUBLIC_MUI_PRO_LICENSE_KEY"));
if (typeof window !== "undefined") {
  window.APP_VERSION = getEnv("APP_VERSION");
}

export const MainLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Suspense>
      <Script src="https://home-c74.niceincontact.com/inContact/ChatClient/js/embed.min.js" />
      <LDProvider>
        <Authentication>
          <IdleLogout />
          <ApolloProvider>
            <OrderLoaderProvider>
              <Datadog />
              <Translations>
                <ThemeProvider>
                  <Notification>
                    <ChatBox />
                    <SurveillanceModeError />
                    {children}
                  </Notification>
                </ThemeProvider>
              </Translations>
            </OrderLoaderProvider>
          </ApolloProvider>
        </Authentication>
      </LDProvider>
    </Suspense>
  );
};
