import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { useAuth } from "services/auth/AuthContext";
import { useMaybeOrder } from "services/orders/OrderLoaderContext";

// https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#identify-user-session
export function DatadogUser() {
  const order = useMaybeOrder();
  const { userId } = useAuth();
  useEffect(() => {
    if (userId) {
      datadogRum.setUser({
        id: userId,
      });
    }
    return () => datadogRum.clearUser();
  }, [userId]);

  useEffect(() => {
    if (order) {
      datadogRum.setUserProperty("orderId", order.id);
      datadogRum.setUserProperty("customerId", order.customer.id);
    }
  }, [order]);

  return null;
}
