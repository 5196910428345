import { ReactNode, useCallback, useEffect } from "react";
import { OktaAuth, toRelativeUrl, AuthState } from "@okta/okta-auth-js";
import { getEnv, debug } from "tools";
import { Security, useOktaAuth } from "@okta/okta-react";
import { Login } from "components/pages/Auth/Login";
import { ApplicationRecord } from "models";
import { AppLayout } from "components/common/layouts/AppLayout";
import { disableAuth } from "./disableAuth";
import { LoadingDisplay } from "components";
import { LegalModal } from "./LegalModal";
import { UserContextProvider } from "./UserContext";
import { useRouter } from "next/navigation";

interface AuthenticationProps {
  children: ReactNode;
}

const oktaDomain = getEnv("NEXT_PUBLIC_CUSTOMER_UI_OKTA_DOMAIN");
const clientId = getEnv("NEXT_PUBLIC_CUSTOMER_UI_OKTA_CLIENT_ID");
const serverId = getEnv("NEXT_PUBLIC_CUSTOMER_UI_OKTA_SERVER_ID");

const auth = new OktaAuth({
  issuer: `https://${oktaDomain}/oauth2/${serverId}`,
  clientId: clientId,
  scopes: ["access_cfe", "openid", "email", "offline_access"],
  redirectUri:
    typeof window !== "undefined"
      ? window.location.origin + "/login/callback"
      : "",
});

// Because spraypaint exists outside of the react life cycle we setup
// the auth information for it here and not inside of a render function.
if (disableAuth) {
  debug("USING MOCK TOKEN AND NOT AUTHENTICATING WITH OKTA");
  ApplicationRecord.jwt = "MOCK_TOKEN";
} else {
  const callback = (authState: AuthState) => {
    const accessToken = authState?.accessToken?.accessToken;
    debug("Authentication: setting JWT", accessToken);
    ApplicationRecord.jwt = accessToken;
  };

  auth.authStateManager.subscribe(callback);
}

/**
 * The LoginGuard is used to wrap the app and prevent loading components/services
 * which need the user to be logged in to display. It must be beneath the MUI/Theme providers
 * as it manages rendering the login screen.
 */
export function LoginGuard({ children }: AuthenticationProps) {
  const { authState } = useOktaAuth();

  if (!authState) {
    return <LoadingDisplay />;
  }

  return disableAuth || authState?.isAuthenticated ? (
    <>
      <LegalModal />
      {children}
    </>
  ) : (
    <AppLayout>
      <Login />
    </AppLayout>
  );
}

/**
 * Wrapper around Okta's Security componponent which:
 * - Supports disabling auth in debug
 * - Sets the JWT for spraypaint
 * - Sets the callback upon a succesful login
 *
 * This component should ideally be as high as possible in the tree to avoid rerenders.
 */
export function Authentication({ children }: AuthenticationProps) {
  const router = useRouter();
  const restoreOriginalUri = useCallback(
    async (_oktaAuth: OktaAuth, originalUri: string) => {
      debug("Authentication: Logging in and redirecting user.");
      router.push(toRelativeUrl(originalUri || "/", window.location.origin));
    },
    []
  );

  // see this comment https://github.com/okta/okta-react/issues/227#issuecomment-1167375982
  // This effect removes the `restoreOriginalUri` callback when this component is unmounted
  // to avoid issues that occur when multiple `restoreOriginalUri` callbacks are set which
  // will always happen in dev due to reactStrict mode and might happen in prod.
  useEffect(() => {
    return () => {
      auth.options.restoreOriginalUri = undefined;
    };
  }, []);

  return (
    <Security oktaAuth={auth} restoreOriginalUri={restoreOriginalUri}>
      <UserContextProvider>{children}</UserContextProvider>
    </Security>
  );
}
