import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";
import { object, string } from "yup";
import { getEnv, debug, isRunningCypress } from "tools";
import { useEffectOnce } from "react-use";
import { Temporal } from "@movehq/datetime";
const initSchema = object({
  applicationId: string().required(),
  clientToken: string().required(),
  site: string().required(),
  service: string().required(),
  env: string().required(),
  version: string().required(),
});

async function startDatadog() {
  debug("APP VERSION", getEnv("APP_VERSION"));
  const dataDogInit: RumInitConfiguration = {
    applicationId: getEnv("NEXT_PUBLIC_DATADOG_APPLICATION_ID"),
    clientToken: getEnv("NEXT_PUBLIC_DATADOG_CLIENT_TOKEN"),
    site: "ddog-gov.com",
    service: "mahalo",
    env: getEnv("NEXT_PUBLIC_DATADOG_ENV"),
    version: getEnv("APP_VERSION"),
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [/https:\/\/api.*\.homesafeconnect\.com/],
  };

  try {
    datadogRum.init(await initSchema.validate(dataDogInit));
    datadogRum.startSessionReplayRecording();
    datadogRum.setGlobalContextProperty("head_git_sha", process.env.HEAD_SHA);
  } catch (error) {
    debug("Datadog not started, missing configuration.", error);
  }
}

/**
 * Service which bootstraps Datadog RUM (Real User Monitoring)
 * @returns null
 */
export function Datadog() {
  useEffectOnce(() => {
    if (!isRunningCypress()) {
      debug("Enabling datadog");
      startDatadog();

      // For analytics we're just logging
      if (Temporal.Now.timeZoneId() === undefined) {
        datadogRum.addError(
          new Error(
            "User is in browser where Temporal.Now.timezoneId is undefined."
          )
        );
      }
    }
  });
  return null;
}
