import { useAtom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { Modal, ModalButtons } from "components/common/ui/Modal";
import { Button } from "components/common/ui/Button";
import { useIntl } from "services";
import { Box } from "@mui/material";
import { useAuth } from "./AuthContext";
import { TermsOfServiceCopy } from "components/common/TermsOfServiceCopy";

const ModalNotRequiredRoutes = [
  "/activate",
  "/login",
  "/login/callback",
  "/tos",
  "/logout",
];

const getInitialValue = () => {
  if (typeof window !== "undefined") {
    const currentPath = window.location.pathname;

    // Hide Legal modal on pages that do not require auth
    if (ModalNotRequiredRoutes.includes(currentPath)) {
      return true;
    }
    const val = window.sessionStorage.getItem("acknowledgedDisclaimer");
    switch (val) {
      case "true":
        return true;
      default:
        return false;
    }
  }
  return false;
};

export const acknowledgedDisclaimerAtom = atomWithStorage(
  "acknowledgedDisclaimer",
  getInitialValue(),
  createJSONStorage(() => sessionStorage)
);

export function LegalModal() {
  const [acknowledgedDisclaimer, setAcknowledgedDisclaimer] = useAtom(
    acknowledgedDisclaimerAtom
  );

  const { signOut } = useAuth();
  const { formatMessage } = useIntl();

  return (
    <Modal
      open={acknowledgedDisclaimer === false}
      title={formatMessage({ id: "disclaimer.strongLanguage" })}
      width={600}
    >
      <Box sx={{ marginBottom: 3 }}>
        <TermsOfServiceCopy />
      </Box>
      <ModalButtons>
        <Button
          onClick={() => signOut("rejected_disclaimer")}
          variant="secondary"
          data-testid="decline-disclaimer-button"
        >
          Logout
        </Button>
        <Button
          data-testid="accept-disclaimer-button"
          onClick={() => setAcknowledgedDisclaimer(true)}
        >
          Accept
        </Button>
      </ModalButtons>
    </Modal>
  );
}
